import { Route } from '@angular/router';

import { AuthGuard } from '@celum/authentication';

import { NoPermissionComponent } from './no-permission/no-permission.component';
import { PermissionGuard } from './permission.guard';

export const appRoutes: Route[] = [
  {
    path: 'logout',
    loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent)
  },
  {
    path: 'no-permission',
    component: NoPermissionComponent
  },
  {
    path: 'home',
    loadComponent: () => import('@celum/formats/features/format-list').then(mod => mod.FormatListComponent),
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];
