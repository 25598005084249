import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, EmptyPage, EmptyPageConfig } from '@celum/common-components';

@Component({
  selector: 'no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss'],
  standalone: true,
  imports: [TranslateModule, CelumEmptyPageModule]
})
export class NoPermissionComponent {
  protected noPermissionPageConfig: EmptyPageConfig = EmptyPage.noActionConfig(
    'no-formats-permission',
    '../../assets/images/no-formats-permission.svg',
    'FORMATS.NO_PERMISSION',
    'normal',
    232,
    '40rem'
  );
}
