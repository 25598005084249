import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Format } from '@celum/formats/domain';

export const formatActions = createActionGroup({
  source: '[Format]',
  events: {
    'Load Formats Batch': emptyProps(),
    'Load Formats Batch Success': props<{ formatsBatch: Format[]; total: number }>(),
    'Load Formats Batch Failure': props<{ error: any; message: string }>(),
    'Delete Format': props<{ formatId: number }>(),
    'Delete Format Success': props<{ formatId: number }>(),
    'Delete Format Failure': props<{ error: any; message: string }>()
  }
});
