import { createReducer, on } from '@ngrx/store';

import { formatActions } from './format.actions';
import { formatAdapter, initialFormatState } from './format.state';

export const formatReducer = createReducer(
  initialFormatState,
  on(formatActions.loadFormatsBatch, state => ({ ...state, loading: true })),
  on(formatActions.loadFormatsBatchFailure, state => ({ ...state, loading: false })),
  on(formatActions.loadFormatsBatchSuccess, (state, { formatsBatch, total }) => ({
    ...formatAdapter.addMany(formatsBatch, state),
    loading: false,
    pagination: {
      totalCount: total
    }
  })),
  on(formatActions.deleteFormat, state => ({ ...state, loading: true })),
  on(formatActions.deleteFormatFailure, state => ({ ...state, loading: false })),
  on(formatActions.deleteFormatSuccess, (state, { formatId }) => ({ ...formatAdapter.removeOne(formatId, state), loading: false }))
);
