import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { Format } from '@celum/formats/domain';

export interface FormatState extends EntityState<Format> {
  sort: Sort;
  loading: boolean;
  pagination: {
    totalCount: number;
  };
}

export const formatAdapter = createEntityAdapter<Format>();

export const initialFormatState: FormatState = formatAdapter.getInitialState({
  loading: false,
  sort: null,
  pagination: {
    totalCount: 0
  }
});
