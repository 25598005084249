import { FormatState, initialFormatState } from './format/format.state';

export interface AppState {
  formats: FormatState;
}

export function initialAppState(): AppState {
  return {
    formats: initialFormatState
  };
}
