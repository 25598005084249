import { createSelector } from '@ngrx/store';

import { formatAdapter } from './format.state';
import { AppState } from '../app.state';

export const selectFormatState = (state: AppState) => state.formats;

export const selectFormatAll = createSelector(selectFormatState, formatAdapter.getSelectors().selectAll);

export const selectFormatsLoading = createSelector(selectFormatState, state => state.loading);

export const selectFormatsTotalCount = createSelector(selectFormatState, state => state.pagination.totalCount);

export const selectFormats = createSelector(selectFormatAll, formats => formats);

export const selectFormatsPagination = createSelector(selectFormatState, state => state.pagination);
