import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SystembarComponent, SystembarConfiguration } from '@celum/shared/ui';
import { LanguageInitializationService, removeCelumLoadingOverlay } from '@celum/shared/util';

@Component({
  standalone: true,
  imports: [RouterModule, SystembarComponent],
  selector: 'formats-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private languageInitializationService: LanguageInitializationService) {
    this.languageInitializationService.init();
  }

  protected systembarConfiguration: SystembarConfiguration = {
    itemProvider: () => []
  };

  protected removeLoadingOverlay(): void {
    removeCelumLoadingOverlay();
  }
}
