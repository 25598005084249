import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { UUIDGenerator } from '@celum/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private store = inject(Store);

  public info(message: string): void {
    this.store.dispatch(new ShowSnackbar(UUIDGenerator.generateId(), SimpleSnackbar, SnackbarConfiguration.success(message)));
  }

  public error(message: string): void {
    this.store.dispatch(new ShowSnackbar(UUIDGenerator.generateId(), SimpleSnackbar, SnackbarConfiguration.error(message)));
  }
}
