import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AccountAccessStatus, AccountUserRole, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.userService.currentUser$.pipe(
      isTruthy(),
      map(currentUser => {
        const accounts = currentUser.accountAccesses;
        const managedAccounts = accounts.filter(account => account.status === AccountAccessStatus.ACTIVE && account.role === AccountUserRole.MANAGER);
        const isAccountManager = managedAccounts.length > 0;
        const isAdmin = currentUser.admin;

        if (isAdmin || isAccountManager) {
          return true;
        } else {
          this.router.navigateByUrl('/no-permission');
          return false;
        }
      })
    );
  }
}
