import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { AppState, FormatEffects, formatReducer, initialAppState } from '@celum/formats/shared';

const reducers: ActionReducerMap<AppState> = {
  formats: formatReducer
};

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      initialState: initialAppState
    }),
    EffectsModule.forRoot([FormatEffects])
  ],
  providers: []
})
export class FormatsAppStoreModule {}
