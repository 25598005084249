import { bootstrapApplication } from '@angular/platform-browser';

import { SnackbarList } from '@celum/internal-components';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .then(appRef => appRef.bootstrap(SnackbarList))
  .catch(err => console.error(err));
