import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';
import { FormatList } from '@celum/formats/domain';

@Injectable({ providedIn: 'root' })
export class FormatService {
  private http = inject(HttpClient);

  private apiUrl = CelumPropertiesProvider.properties.appProperties.formats.apiUrl;

  public getFormats(): Observable<FormatList> {
    // The specifications are hardcoded for now, but they should be handled by BE in future
    const specifications: string[] = ['downloadAudio', 'downloadImage', 'downloadVideo', 'injectXmp', 'stillFrame'];
    return this.http.get<FormatList>(`${this.apiUrl}/formats`, { params: { specifications } });
  }

  public deleteFormat(formatId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/formats/${formatId}`);
  }
}
